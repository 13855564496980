import AddressAutocomplete from "../../components/AddressAutocomplete/AddressAutocomplete";
import ExclamationMark from "../../components/ExclamationMark/ExclamationMark";
import StyledTextArea from "../../components/StyledInputs/StyledTextArea";
import StyledSelect from "../../components/StyledInputs/StyledSelect";
import StyledInput from "../../components/StyledInputs/StyledInput";
import DotSpinner from "../../components/DotSpinner/DotSpinner";
import Map from "../../components/Map/Map";
import {
  animationVariants,
  FixedPublicPlaceTypes,
} from "../../core/constants/constants";
import { IPublicPlaceBinsPath } from "../../core/types/types";
import { motion } from "framer-motion";
import { FormEvent, useState } from "react";

function ServiceRequestWithMapPath({
  serviceRequest,
  updateServiceRequest,
  onSubmit,
  isLoading,
  formErrorMessage,
}: IPublicPlaceBinsPath) {
  const [addressErrorMessage, updateAddressErrorMessage] = useState<
    string | null
  >(null);
  const [isValidAddress, updateIsValidAddress] = useState(false);

  return (
    <motion.form
      variants={animationVariants}
      initial="initial"
      animate="in"
      exit="out"
      className="flex flex-col items-center w-full"
      onSubmit={(e: FormEvent) => {
        e.preventDefault();
        // prevents double submission
        if (!isLoading) {
          onSubmit();
        }
      }}
    >
      <motion.div variants={animationVariants} className="w-full">
        <StyledSelect
          label="Select from the following"
          dropdownItems={FixedPublicPlaceTypes.publicPlaceBinsList}
          onChange={(e) =>
            updateServiceRequest({
              ...serviceRequest,
              publicLitterType: (e.target as HTMLInputElement).value,
            })
          }
        />
      </motion.div>
      <motion.div variants={animationVariants} className="w-full">
        <StyledTextArea
          label="Add any additional comments (optional)"
          required={false}
          onChange={(e) =>
            updateServiceRequest({
              ...serviceRequest,
              comments: (e.target as HTMLInputElement).value,
            })
          }
        />
      </motion.div>
      <motion.div variants={animationVariants} className="w-full">
        <StyledInput
          label="Enter your full name"
          onChange={(e) =>
            updateServiceRequest({
              ...serviceRequest,
              name: (e.target as HTMLInputElement).value,
            })
          }
        />
      </motion.div>
      <motion.div variants={animationVariants} className="w-full">
        <StyledInput
          label="Enter your email address"
          type="email"
          onChange={(e) =>
            updateServiceRequest({
              ...serviceRequest,
              emailAddress: (e.target as HTMLInputElement).value,
            })
          }
        />
      </motion.div>
      <motion.div variants={animationVariants} className="w-full">
        <StyledInput
          label="Enter your phone number"
          type="number"
          onChange={(e) =>
            updateServiceRequest({
              ...serviceRequest,
              phoneNo: (e.target as HTMLInputElement).value,
            })
          }
        />
      </motion.div>
      <motion.div variants={animationVariants} className="w-full z-50">
        <AddressAutocomplete
          label="Enter the approximate address"
          serviceRequest={serviceRequest}
          updateServiceRequest={updateServiceRequest}
          addressErrorMessage={addressErrorMessage}
          updateAddressErrorMessage={updateAddressErrorMessage}
          isValidAddress={isValidAddress}
          updateIsValidAddress={updateIsValidAddress}
        />
      </motion.div>
      <motion.div variants={animationVariants} className="w-full mt-4">
        <Map
          serviceRequest={serviceRequest}
          updateServiceRequest={updateServiceRequest}
        />
      </motion.div>
      {formErrorMessage && (
        <motion.div
          variants={animationVariants}
          className="mb-4 bg-white rounded w-full"
        >
          <div className="m-2 p-2 relative border border-red-500 rounded flex items-center justify-end">
            <p className="mr-0 flex-grow text-red-500 ">{formErrorMessage}</p>
            <div className="flex-shrink-0 mr-2">
              <ExclamationMark />
            </div>
          </div>
        </motion.div>
      )}
      <motion.div
        variants={animationVariants}
        className="w-full flex justify-end"
      >
        <button
          type="submit"
          disabled={
            addressErrorMessage !== null
          }
          className="body-font disabled-button-style w-28 xl:w-40 text-white bg-wdc-green transition duration-300 ease-in-out hover:bg-wdc-green-lighter rounded py-2.5 md:py-3 focus:outline-none border border-transparent focus:border-gray-800"
        >
          {isLoading ? <DotSpinner /> : "Submit"}
        </button>
      </motion.div>
    </motion.form>
  );
};

export default ServiceRequestWithMapPath;
