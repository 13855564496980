import "./DotSpinner.css";

function DotSpinner() {
  return (
    <div className="w-full flex flex-row items-center justify-center">
      <div className="spinner flex flex-row items-center justify-center">
        {/* Invisible text with same font allows height of containing button to remain the same */}
        <p className="body-font opacity-0">.</p>
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
        <p className="body-font opacity-0">.</p>
      </div>
    </div>
  );
};

export default DotSpinner;
