import { useState, FormEvent } from "react";
import ExclamationMark from "../../components/ExclamationMark/ExclamationMark";
import AddressAutocomplete from "../../components/AddressAutocomplete/AddressAutocomplete";
import StyledTextArea from "../../components/StyledInputs/StyledTextArea";
import StyledInput from "../../components/StyledInputs/StyledInput";
import DotSpinner from "../../components/DotSpinner/DotSpinner";
import Select, { MultiValue } from "react-select";
import {
  IMandatoryCommentPath,
} from "../../core/types/types";
import { motion } from "framer-motion";
import {
  animationVariants,
  FixedServiceRequestTypes,
  fixedRuralRecyclingDepots,
} from "../../core/constants/constants";

function MandatoryCommentPath({
  serviceRequest,
  updateServiceRequest,
  onSubmit,
  isLoading,
  formErrorMessage,
  manualAddress,
  updateManualAddress,
  updateBackupStreetNo,
  updateBackupStreetName,
  updateBackupPostCode,
}: IMandatoryCommentPath) {
  const [addressErrorMessage, updateAddressErrorMessage] = useState<string | null>(null); // prettier-ignore
  const [isValidAddress, updateIsValidAddress] = useState(false);

  function handleRuralRecycling(e: MultiValue<{ value: string; label: string; }>) {
    let currentSelectedItems = [];
    for (let i = 0; i < e.length; i++) {
      const value: any = e[i].value;
      currentSelectedItems.push(value);
    }

    if (currentSelectedItems.length > 0) {
      updateServiceRequest({
        ...serviceRequest,
        ruralRecyclingDepot: currentSelectedItems,
      });
    } else {
      updateServiceRequest({
        ...serviceRequest,
        ruralRecyclingDepot: null,
      });
    }
  }

  const customStyles = {
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      padding: 12,
    }),
  }

  return (
    <motion.form
      variants={animationVariants}
      initial="initial"
      animate="in"
      exit="out"
      className="flex flex-col items-center w-full"
      onSubmit={(e: FormEvent) => {
        e.preventDefault();
        // prevents double submission
        if (!isLoading) {
          onSubmit();
        }
      }}
    >
      {serviceRequest.serviceRequest ===
        FixedServiceRequestTypes.RURAL_RECYCLING_DEPOT && (
          <motion.div variants={animationVariants} className="w-full pb-3">
            <Select
              options={fixedRuralRecyclingDepots}
              styles={customStyles}
              placeholder="Select your Rural Recycling Depot"
              isSearchable={false}
              isMulti
              onChange={handleRuralRecycling}
            />
          </motion.div>
      )}
      <motion.div variants={animationVariants} className="w-full">
        <StyledTextArea
          label="Add any additional comments"
          required={true}
          onChange={(e) =>
            updateServiceRequest({
              ...serviceRequest,
              comments: (e.target as HTMLInputElement).value,
            })
          }
        />
      </motion.div>
      {(serviceRequest.serviceRequest ===
        FixedServiceRequestTypes.CUSTOMER_COMPLAINT ||
        serviceRequest.serviceRequest ===
          FixedServiceRequestTypes.RESOURCE_RECOVERY_PARK) && (
        <motion.div variants={animationVariants} className="w-full">
          <motion.div variants={animationVariants} className="w-full">
            <StyledInput
              label="Enter your full name"
              onChange={(e) =>
                updateServiceRequest({
                  ...serviceRequest,
                  name: (e.target as HTMLInputElement).value,
                })
              }
            />
          </motion.div>
          <motion.div variants={animationVariants} className="w-full">
            <StyledInput
              label="Enter your email address"
              onChange={(e) =>
                updateServiceRequest({
                  ...serviceRequest,
                  emailAddress: (e.target as HTMLInputElement).value,
                })
              }
            />
          </motion.div>
          <motion.div variants={animationVariants} className="w-full">
            <StyledInput
              label="Enter your phone number"
              type="number"
              onChange={(e) =>
                updateServiceRequest({
                  ...serviceRequest,
                  phoneNo: (e.target as HTMLInputElement).value,
                })
              }
            />
          </motion.div>
          <motion.div variants={animationVariants} className="w-full mb-4">
            <div className="bg-white rounded border border-gray-300 shadow-sm px-4 py-3">
              <p className="text-base text-gray-500 mb-2">
                Enter your address in the field below
              </p>
              {manualAddress ? (
                <div className="w-full">
                  {isValidAddress &&
                    serviceRequest.addressComponents.StreetNumber === "" && (
                      <div className="mb-4 flex flex-row items-center w-full px-4 py-3 border border-gray-300 rounded">
                        <label
                          htmlFor="Enter your street number"
                          className="text-base text-gray-500 flex-grow mr-4"
                        >
                          Sorry, we couldn't find an address with your street
                          number. This can happen with new properties or
                          subdivisions. Please enter your address manually
                          before proceeding.
                        </label>
                        <div className="flex-shrink-0 mr-2 mb-2">
                          <ExclamationMark color="#a2a2a2" />
                        </div>
                      </div>
                    )}
                  <div className="w-full grid grid-cols-3 gap-x-4">
                    <StyledInput
                      label="Street number"
                      type="text"
                      margin="mb-0"
                      onChange={(e) =>
                        updateBackupStreetNo(
                          (e.target as HTMLInputElement).value.trim()
                        )
                      }
                    />
                    <StyledInput
                      label="Street name"
                      type="text"
                      margin="mb-0"
                      onChange={(e) =>
                        updateBackupStreetName(
                          (e.target as HTMLInputElement).value.trim()
                        )
                      }
                    />
                    <StyledInput
                      label="Post code"
                      type="number"
                      margin="mb-0"
                      onChange={(e) =>
                        updateBackupPostCode(
                          (e.target as HTMLInputElement).value.trim()
                        )
                      }
                    />
                  </div>
                </div>
              ) : (
                <AddressAutocomplete
                  label="Start typing your address"
                  serviceRequest={serviceRequest}
                  updateServiceRequest={updateServiceRequest}
                  addressErrorMessage={addressErrorMessage}
                  updateAddressErrorMessage={updateAddressErrorMessage}
                  isValidAddress={isValidAddress}
                  updateIsValidAddress={updateIsValidAddress}
                  updateBackupStreetNo={updateBackupStreetNo}
                  updateBackupPostcode={updateBackupPostCode}
                  updateManualAddress={updateManualAddress}
                />
              )}
              <label
                htmlFor="manual-address"
                className="text-base text-gray-500 inline-flex flex-row items-center mt-2"
              >
                <input
                  name="manual-address"
                  id="manual-address"
                  type="checkbox"
                  checked={manualAddress}
                  className="mr-2 h-5"
                  onChange={() => updateManualAddress(!manualAddress)}
                />
                Let me manually enter my address
              </label>
            </div>
          </motion.div>
        </motion.div>
      )}
      {formErrorMessage && (
        <motion.div
          variants={animationVariants}
          className="mb-4 bg-white rounded w-full"
        >
          <div className="m-2 p-2 relative border border-red-400 rounded flex items-center justify-end">
            <p className="mr-0 flex-grow text-red-400 ">{formErrorMessage}</p>
            <div className="flex-shrink-0 mr-2">
              <ExclamationMark />
            </div>
          </div>
        </motion.div>
      )}
      <motion.div
        variants={animationVariants}
        className="w-full flex justify-end"
      >
        <button
          type="submit"
          disabled={!manualAddress && addressErrorMessage !== null}
          className="body-font disabled-button-style w-28 xl:w-40 text-white bg-wdc-green transition duration-300 ease-in-out hover:bg-wdc-green-lighter rounded py-2.5 md:py-3 focus:outline-none border border-transparent focus:border-gray-800"
        >
          {isLoading ? <DotSpinner /> : "Submit"}
        </button>
      </motion.div>
    </motion.form>
  );
};

export default MandatoryCommentPath;
