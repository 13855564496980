import { IStyledSelect } from "../../core/types/types";

function StyledSelect({
  label,
  required = true,
  dropdownItems,
  onChange,
}: IStyledSelect) {
  return (
    <div className="h-full w-full mb-4 flex flex-col">
      {/* <label htmlFor={label} className="subheading-font pb-2">
        {label}
      </label> */}
      <div className="shadow-sm rounded flex relative">
        <select
          name={label}
          required={required}
          id={label}
          onChange={onChange}
          title={label}
          defaultValue=""
          className="bg-white appearance-none z-10 pl-3 text-base transition duration-300 ease-in-out placeholder-gray-500 text-gray-800 py-3 w-full border border-transparent focus:outline-none focus:border-gray-800 rounded"
        >
          <option disabled value="">
            {label}
          </option>
          {dropdownItems &&
            Object.entries(dropdownItems).map(
              ([itemKey, itemValue]: [string, string], index: number) => (
                <option value={itemKey} key={index}>
                  {itemValue}
                </option>
              )
            )}
        </select>
        <div
          className="px-4 flex items-center border-l border-gray-300 flex-col justify-center text-gray-500
    absolute right-0 bottom-0 top-0 mx-auto z-20 pointer-events-none "
        >
          <svg
            tabIndex={-1}
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-chevron-up"
            width={16}
            height={16}
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" />
            <polyline points="6 15 12 9 18 15" />
          </svg>
          <svg
            tabIndex={-1}
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-chevron-down"
            width={16}
            height={16}
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" />
            <polyline points="6 9 12 15 18 9" />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default StyledSelect;
