import { FormEvent, SyntheticEvent, useState, useEffect } from "react";
import AddressAutocomplete from "../../components/AddressAutocomplete/AddressAutocomplete";
import ExclamationMark from "../../components/ExclamationMark/ExclamationMark";
import StyledCheckboxes from "../../components/StyledInputs/StyledCheckboxes";
import StyledTextArea from "../../components/StyledInputs/StyledTextArea";
import StyledInput from "../../components/StyledInputs/StyledInput";
import DotSpinner from "../../components/DotSpinner/DotSpinner";
import { IStandardServicePath } from "../../core/types/types";
import { motion } from "framer-motion";
import {
  animationVariants,
  FixedServiceRequestTypes,
  FixedServiceTypes,
  BinSizes,
} from "../../core/constants/constants";

function StandardServicePath({
  serviceRequest,
  updateServiceRequest,
  onSubmit,
  checkboxErrorMessage,
  updateCheckboxErrorMessage,
  isLoading,
  formErrorMessage,
  valuationNoError,
  updateValuationNoError,
  manualAddress,
  updateManualAddress,
  updateBackupStreetNo,
  updateBackupStreetName,
  updateBackupPostCode,
} : IStandardServicePath) {
  const [addressErrorMessage, updateAddressErrorMessage] = useState<
    string | null
  >(null);
  const [isValidAddress, updateIsValidAddress] = useState(false);
  const [disableRubbish, setDisableRubbish] = useState<boolean>(false);
  const [disableGlass, setDisableGlass] = useState<boolean>(false);

  // Update service list in service request state when a checkbox is ticked or unticked
  function serviceRequestTypeCheckboxHandler(e: SyntheticEvent) {
    updateCheckboxErrorMessage(null);

    const value = (e.target as HTMLInputElement).value;

    if (serviceRequest.service === null) {
      updateServiceRequest({
        ...serviceRequest,
        service: [value],
      });
    } else if (!serviceRequest.service?.includes(value)) {
      updateServiceRequest({
        ...serviceRequest,
        service: [...serviceRequest.service, value],
      });
    } else {
      updateServiceRequest({
        ...serviceRequest,
        service: serviceRequest.service.filter((item) => item !== value),
      });
    }
  }

  // Update service list in service request state when a checkbox is ticked or unticked when it includes bin sizes
  function serviceRequestWithBinSizeTypeCheckboxHandler(e: SyntheticEvent) {
    updateCheckboxErrorMessage(null);

    const value: string = (e.target as HTMLInputElement).value;
    let service: string = "";

    if (value === BinSizes.RUBBISH_RED_140_LITRE) {
      service = FixedServiceTypes.RUBBISH;
    } else if (value === BinSizes.RUBBISH_RED_240_LITRE) {
      service = FixedServiceTypes.RUBBISH;
    } else if (value === BinSizes.RECYCLING_YELLOW_240_LITRE) {
      service = FixedServiceTypes.RECYCLING;
    } else if (value === BinSizes.ORGANICS_GREEN_240_LITRE) {
      service = FixedServiceTypes.ORGANICS;
    } else if (value === BinSizes.GLASS_BLUE_45_LITRE) {
      service = FixedServiceTypes.GLASS;
    } else if (value === BinSizes.GLASS_BLUE_240_LITRE) {
      service = FixedServiceTypes.GLASS;
    }

    if (serviceRequest.binSize === null) {
      updateServiceRequest({
        ...serviceRequest,
        binSize: [value],
        service: [service],
      });
      if (service === FixedServiceTypes.RUBBISH) {
        setDisableRubbish(true);
      } else if (service === FixedServiceTypes.GLASS) {
        setDisableGlass(true);
      }
    } else if (
      serviceRequest.service?.includes(service) &&
      !serviceRequest.binSize?.includes(value)
    ) {
      // Removes logic if target values binsize has already been added
    } else if (!serviceRequest.binSize?.includes(value)) {
      updateServiceRequest({
        ...serviceRequest,
        binSize: [...serviceRequest.binSize, value],
        service: [...serviceRequest.service, service],
      });
      if (service === FixedServiceTypes.RUBBISH) {
        setDisableRubbish(true);
      } else if (service === FixedServiceTypes.GLASS) {
        setDisableGlass(true);
      }
    } else {
      updateServiceRequest({
        ...serviceRequest,
        binSize: serviceRequest.binSize.filter((item) => item !== value),
        service: serviceRequest.service.filter((item) => item !== service),
      });
      if (service === FixedServiceTypes.RUBBISH) {
        setDisableRubbish(false);
      } else if (service === FixedServiceTypes.GLASS) {
        setDisableGlass(false);
      }
    }
  }

  useEffect(() => {
    setDisableRubbish(false);
    setDisableGlass(false);
  }, [serviceRequest.serviceRequest]);

  return (
    <motion.form
      variants={animationVariants}
      initial="initial"
      animate="in"
      exit="out"
      className="flex flex-col items-center w-full"
      onSubmit={(e: FormEvent) => {
        e.preventDefault();
        // prevents double submission
        if (!isLoading) {
          onSubmit();
        }
      }}
    >
      <motion.div variants={animationVariants} className="w-full">
        <StyledCheckboxes
          serviceRequest={serviceRequest}
          label={
            serviceRequest.serviceRequest ===
            FixedServiceRequestTypes.ADDITIONAL_BIN
              ? "Select the services that relate to your request. Please note that you must be a ratepayer to request any additional bins"
              : serviceRequest.serviceRequest ===
                FixedServiceRequestTypes.REMOVE_BIN
              ? "Select the bin you would like to remove "
              : "Select the services that relate to your request"
          }
          onChange={(e) => {
            if (
              serviceRequest.serviceRequest ===
                FixedServiceRequestTypes.ADDITIONAL_BIN ||
              serviceRequest.serviceRequest ===
                FixedServiceRequestTypes.REMOVE_BIN
            ) {
              serviceRequestWithBinSizeTypeCheckboxHandler(e);
            } else {
              serviceRequestTypeCheckboxHandler(e);
            }
          }}
          checkboxValuesList={
            serviceRequest.serviceRequest ===
              FixedServiceRequestTypes.ADDITIONAL_BIN ||
            serviceRequest.serviceRequest ===
              FixedServiceRequestTypes.REMOVE_BIN
              ? BinSizes.binSizeList
              : FixedServiceTypes.serviceList
          }
          checkboxErrorMessage={checkboxErrorMessage}
          disableRubbish={disableRubbish}
          disableGlass={disableGlass}
        />
      </motion.div>
      <motion.div variants={animationVariants} className="w-full">
        <StyledTextArea
          label="Add any additional comments (optional)"
          required={false}
          onChange={(e) =>
            updateServiceRequest({
              ...serviceRequest,
              comments: (e.target as HTMLInputElement).value,
            })
          }
        />
      </motion.div>
      <motion.div variants={animationVariants} className="w-full">
        <StyledInput
          label="Enter your full name"
          onChange={(e) =>
            updateServiceRequest({
              ...serviceRequest,
              name: (e.target as HTMLInputElement).value,
            })
          }
        />
      </motion.div>
      <motion.div variants={animationVariants} className="w-full">
        <StyledInput
          label="Enter your email address"
          onChange={(e) =>
            updateServiceRequest({
              ...serviceRequest,
              emailAddress: (e.target as HTMLInputElement).value,
            })
          }
        />
      </motion.div>
      <motion.div variants={animationVariants} className="w-full">
        <StyledInput
          label="Enter your phone number"
          type="number"
          onChange={(e) =>
            updateServiceRequest({
              ...serviceRequest,
              phoneNo: (e.target as HTMLInputElement).value,
            })
          }
        />
      </motion.div>
      {(serviceRequest.serviceRequest ===
        FixedServiceRequestTypes.ADDITIONAL_BIN ||
        serviceRequest.serviceRequest ===
          FixedServiceRequestTypes.REMOVE_BIN) && (
        <div className="w-full">
          <motion.div variants={animationVariants} className="w-full">
            <StyledInput
              label="Enter your rates valuation number (no special characters)"
              required={false}
              onChange={(e) => {
                updateValuationNoError(null);
                updateServiceRequest({
                  ...serviceRequest,
                  valuationNumber: (e.target as HTMLInputElement).value,
                });
              }}
            />
          </motion.div>
          {valuationNoError && (
            <motion.div
              variants={animationVariants}
              className="mb-4 bg-white inline-block rounded w-full"
            >
              <div className="m-2 p-2 relative border border-red-500 rounded flex items-center justify-end">
                <p className="mr-0 flex-grow text-red-500 ">
                  {valuationNoError}
                </p>
                <div className="flex-shrink-0 mr-2">
                  <ExclamationMark />
                </div>
              </div>
            </motion.div>
          )}
        </div>
      )}
      <motion.div variants={animationVariants} className="w-full mb-4">
        <div className="bg-white rounded border border-gray-300 shadow-sm px-4 py-3">
          <p className="text-base text-gray-500 mb-2">
            Enter your address in the field below
          </p>
          {manualAddress ? (
            <div className="w-full">
              {isValidAddress &&
                serviceRequest.addressComponents.StreetNumber === "" && (
                  <div className="mb-4 flex flex-row items-center w-full px-4 py-3 border border-gray-300 rounded">
                    <label
                      htmlFor="Enter your street number"
                      className="text-base text-gray-500 flex-grow mr-4"
                    >
                      Sorry, we couldn't find an address with your street
                      number. This can happen with new properties or
                      subdivisions. Please enter your address manually before
                      proceeding.
                    </label>
                    <div className="flex-shrink-0 mr-2 mb-2">
                      <ExclamationMark color="#a2a2a2" />
                    </div>
                  </div>
                )}
              <div className="w-full grid grid-cols-3 gap-x-4">
                <StyledInput
                  label="Street number"
                  type="text"
                  margin="mb-0"
                  onChange={(e) =>
                    updateBackupStreetNo(
                      (e.target as HTMLInputElement).value.trim()
                    )
                  }
                />
                <StyledInput
                  label="Street name"
                  type="text"
                  margin="mb-0"
                  onChange={(e) =>
                    updateBackupStreetName(
                      (e.target as HTMLInputElement).value.trim()
                    )
                  }
                />
                <StyledInput
                  label="Post code"
                  type="number"
                  margin="mb-0"
                  onChange={(e) =>
                    updateBackupPostCode(
                      (e.target as HTMLInputElement).value.trim()
                    )
                  }
                />
              </div>
            </div>
          ) : (
            <AddressAutocomplete
              label="Start typing your address"
              serviceRequest={serviceRequest}
              updateServiceRequest={updateServiceRequest}
              addressErrorMessage={addressErrorMessage}
              updateAddressErrorMessage={updateAddressErrorMessage}
              isValidAddress={isValidAddress}
              updateIsValidAddress={updateIsValidAddress}
              updateBackupStreetNo={updateBackupStreetNo}
              updateBackupPostcode={updateBackupPostCode}
              updateManualAddress={updateManualAddress}
            />
          )}
          <label
            htmlFor="manual-address"
            className="text-base text-gray-500 inline-flex flex-row items-center mt-2"
          >
            <input
              name="manual-address"
              id="manual-address"
              type="checkbox"
              checked={manualAddress}
              className="mr-2 h-5"
              onChange={() => updateManualAddress(!manualAddress)}
            />
            Let me manually enter my address
          </label>
        </div>
      </motion.div>
      {formErrorMessage && (
        <motion.div
          variants={animationVariants}
          className="mb-4 bg-white rounded w-full"
        >
          <div className="m-2 p-2 relative border border-red-400 rounded flex items-center justify-end">
            <p className="mr-0 flex-grow text-red-400 ">{formErrorMessage}</p>
            <div className="flex-shrink-0 mr-2">
              <ExclamationMark />
            </div>
          </div>
        </motion.div>
      )}
      <motion.div
        variants={animationVariants}
        className="w-full flex justify-end"
      >
        <button
          type="submit"
          disabled={
            (!manualAddress && addressErrorMessage !== null) ||
            checkboxErrorMessage !== null ||
            valuationNoError != null
          }
          className="body-font disabled-button-style w-28 xl:w-40 text-white bg-wdc-green transition duration-300 ease-in-out hover:bg-wdc-green-lighter rounded py-2.5 md:py-3 focus:outline-none border border-transparent focus:border-gray-800"
        >
          {isLoading ? <DotSpinner /> : "Submit"}
        </button>
      </motion.div>
    </motion.form>
  );
};

export default StandardServicePath;
