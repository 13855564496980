function Footer() {
  return (
    <div className="bg-wdc-grey w-full py-12 relative">
      <div className="w-11/12 md:w-8/12 mx-auto flex flex-row items-center justify-center sm:justify-end">
        <div className="flex flex-col items-start text-white">
          <p className="confirmation-font mb-4">Need help?</p>
          <a href="tel:+6436890000">
            <p className="body-font text-white">+64 3 689 0000</p>
          </a>
          <a href="mailto:council@waimatedc.govt.nz">
            <p className="body-font text-white">council@waimatedc.govt.nz</p>
          </a>
        </div>
      </div>
      <p className="absolute left-1 bottom-1 text-xs text-gray-600">v1.0.1</p>
    </div>
  );
};
export default Footer;
