import { IMap } from "../../core/types/types";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import { useEffect, useMemo, useState } from "react";

function Map({ serviceRequest, updateServiceRequest }: IMap) {
  const initialLatLng = { lat: -44.7311867, lng: 171.0518998 };
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDBUKNe73SRE3Tr1K1crTDO1Jrw6FKSlS0"
  });
  const [markerLatLng, updateMarkerLatLng] = useState<{ lat: number, lng: number } | undefined>();
  const [zoomPosition, updateZoomPosition] = useState(12);
  const [centerPosition, updateCenterPosition] = useState(initialLatLng);
  const serviceRequestLatLng = useMemo(() => {
    return {
      lat: serviceRequest.addressComponents.Latitude,
      lng: serviceRequest.addressComponents.Longitude,
    };
  }, [serviceRequest.addressComponents]);

  // Updates marker latLng, camera position and zoom whenever the service request's latLng is changed
  //? Not ideal, this means that marker position is updated twice when marker is dragged
  //? Also, zoom doesn't change if a user has manually adjusted the zoom
  useEffect(() => {
    if (serviceRequestLatLng.lat !== null && serviceRequestLatLng.lng !== null) {
      updateMarkerLatLng({ lat: serviceRequestLatLng.lat, lng: serviceRequestLatLng.lng });
      updateCenterPosition({ lat: serviceRequestLatLng.lat, lng: serviceRequestLatLng.lng });
      updateZoomPosition(17);
    }
  }, [serviceRequestLatLng]);

  if (!isLoaded) return <div>Loading...</div>

  return (
      <div className="w-full mb-4">
        <div className="h-80 w-full relative">
          <p className="hidden sm:block bg-white p-2 text-base text-gray-500 rounded border border-gray-100 shadow whitespace-nowrap absolute top-2 right-14 z-10 opacity-90">
            Drag and drop the marker for greater accuracy
          </p>
          <GoogleMap zoom={zoomPosition} center={centerPosition} mapContainerClassName="w-full h-full" >
            {markerLatLng && <MarkerF position={markerLatLng} draggable={true} onDragEnd={(e) => updateServiceRequest({
              ...serviceRequest,
              addressComponents: {
                ...serviceRequest.addressComponents,
                Latitude: e.latLng?.lat() ? e.latLng?.lat() : null,
                Longitude: e.latLng?.lng() ? e.latLng?.lng() : null,
              },
            })} />}
          </GoogleMap>
        </div>
      </div>
  )
}

export default Map;
