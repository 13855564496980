import axios from "axios";
import { envConfig } from "../environment/envConfig";
import { IServiceRequest, IServiceRequestWithServiceString } from "../types/types";

axios.defaults.baseURL = envConfig.apiBaseUrl;

// Creates a service request in the database and returns a ticket number to the user
async function createServiceRequest(serviceRequest: IServiceRequest | IServiceRequestWithServiceString) {
  try {
    const env = process.env.REACT_APP_ENVIRONMENT === "prod" ? "prod" : "dev";
    const queryParams = {
      env: env,
      code: envConfig.keys.createServiceRequestKey,
    };
    const response = await axios.post("wdc/createservicerequest", serviceRequest, {
      params: queryParams,
    });
    return response;
  } catch (error: any) {
    // error.response has access to status and data set in API
    return error.response;
  }
};

async function checkAddress(
  houseNo: string | null,
  houseName: string | null,
  postCode: string | null,
  valuationNumber: string | null
) {
  try {
    const env = process.env.REACT_APP_ENVIRONMENT === "prod" ? "prod" : "dev";
    const queryParams = {
      env: env,
      code: envConfig.keys.checkAddressKey,
      houseNo: houseNo,
      houseName: houseName,
      postcode: postCode,
      valuationNumber: valuationNumber,
    };
    const response = await axios.get("wdc/checkaddress", {
      params: queryParams,
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
};

export const apiService = {
  createServiceRequest,
  checkAddress,
};
