const local = {
  requireLogin: false,
  apiBaseUrl: "http://localhost:7071/API/",
  keys: {
    createServiceRequestKey: "",
    checkAddressKey: "",
  },
};

const dev = {
  requireLogin: true,
  apiBaseUrl: "https://environz-api-dev.azurewebsites.net/API/",
  keys: {
    createServiceRequestKey: "wtxg8KH0Hy82VrO3SJyJ2oXSSZNAisYb5gL2hin1NuMHlRniAywYFA==",
    checkAddressKey: "ovF57taafv4Lg0MRzupyAv8umU9HEc0EQTf8PgcXAyGideLUvm90uQ==",
  },
};

const prod = {
  requireLogin: false,
  apiBaseUrl: "https://environz-api.azurewebsites.net/API/",
  keys: {
    createServiceRequestKey: "xiRc9jv5laY1Nq69pVbRdhksyZpMQgTSYxZtdn/mPUAyG3mo3KCMIg==",
    checkAddressKey: "chUBebC/3wsJ4K89sT3op6u0kjasEZeHd5RsTExRtMQpuwTYLiIhkQ==",
  },
};

export const envConfig =
  process.env.REACT_APP_ENVIRONMENT === "prod"
    ? prod
    : process.env.REACT_APP_ENVIRONMENT === "local"
    ? local
    : dev;
