import { IStyledInput } from "../../core/types/types";

function StyledTextArea({ label, required = true, onChange }: IStyledInput) {
  return (
    <div className="h-full w-full mb-4 flex flex-col">
      {/* <label htmlFor={label} className="subheading-font pb-2">
        {label}
      </label> */}
      <textarea
        id={label}
        name={label}
        title={label}
        placeholder={label}
        onChange={onChange}
        required={required}
        className={`${required && "h-36"} border border-gray-300 pl-3 py-3 transition duration-300 ease-in-out shadow-sm rounded text-base focus:outline-none focus:border-gray-800 bg-white placeholder-gray-500 text-gray-800`}
      />
    </div>
  );
};

export default StyledTextArea;
