import { animationVariants } from "../core/constants/constants";
import { IConfirmation } from "../core/types/types";
import { useNavigate } from "react-router";
import { motion } from "framer-motion";
import { useEffect } from "react";

function Confirmation({ hasCompletedForm, serviceRequest }: IConfirmation) {
  const navigate = useNavigate();

  // Prevent manual navigation to page
  useEffect(() => {
    if (!hasCompletedForm) {
      navigate("/");
    }
  }, [navigate, hasCompletedForm]);

  // Scroll to top when component mounts
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <motion.div
      variants={animationVariants}
      initial="initial"
      animate="in"
      exit="out"
      className="sm:mt-10 mb-10 w-11/12 md:w-8/12 xl:6/12"
    >
      <div className="py-12 my-auto mx-auto flex flex-col items-start justify-start xl:w-4/5 2xl:w-6/12">
        <motion.h1
          variants={animationVariants}
          className="heading-font mb-6 xl:mb-10 w-full text-left"
        >
          Thank you
        </motion.h1>
        <motion.p variants={animationVariants} className="mb-3 confirmation-font">
          We'll be in touch with you as soon as possible.
        </motion.p>
        <motion.p variants={animationVariants} className="mb-10 xl:mb-16 confirmation-font">
          {`Your ticket number is #${serviceRequest.ticketNumber}.\nPlease keep this number as a reference for all enquiries related to this job.`}
        </motion.p>
        <motion.div variants={animationVariants} className="flex items-center">
          <a
            href="/"
            className="mr-4 body-font text-white bg-wdc-green transition duration-300 ease-in-out hover:bg-wdc-green-lighter rounded px-6 py-2.5 md:px-10 md:py-3 focus:outline-none border border-transparent focus:border-gray-800"
          >
            Report another issue
          </a>
          <a
            href="https://www.waimatedc.govt.nz/"
            className="body-font text-white bg-wdc-green transition duration-300 ease-in-out hover:bg-wdc-green-lighter rounded px-6 py-2.5 md:px-10 md:py-3 focus:outline-none border border-transparent focus:border-gray-800"
          >
            WDC website
          </a>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Confirmation;
