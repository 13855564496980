import React from "react";
import "./CircleSpinner.css";

function CircleSpinner() {
  return (
    <div className="h-full">
      <div className="lds-ring flex flex-col items-center justify-center h-full">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default CircleSpinner;
