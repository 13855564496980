function NotFound() {
  return (
    <div className="sm:mt-10 mb-10 w-11/12 md:w-8/12 xl:6/12">
      <div className="py-12 my-auto mx-auto flex flex-col items-start justify-start xl:w-4/5 2xl:w-6/12">
        <h1 className="heading-font mb-6 xl:mb-10 w-full text-left">Page Not Found</h1>
        <p className="mb-10 xl:mb-16 confirmation-font">
          We couldn't find the page you were looking for. Click the button below to return to the
          homepage.
        </p>

        <a
          href="/"
          className="mr-4 body-font text-white bg-wdc-green transition duration-300 ease-in-out hover:bg-wdc-green-lighter rounded px-6 py-2.5 md:px-10 md:py-3 focus:outline-none border border-transparent focus:border-gray-800"
        >
          Take me home
        </a>
      </div>
    </div>
  );
};

export default NotFound;
