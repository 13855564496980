import { FormEvent, useState } from "react";
import ExclamationMark from "../../components/ExclamationMark/ExclamationMark";
import StyledTextArea from "../../components/StyledInputs/StyledTextArea";
import StyledInput from "../../components/StyledInputs/StyledInput";
import DotSpinner from "../../components/DotSpinner/DotSpinner";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IEventBinPath } from "../../core/types/types";
import { motion } from "framer-motion";
import { animationVariants } from "../../core/constants/constants";

function EventBinPath({
  serviceRequest,
  updateServiceRequest,
  onSubmit,
  isLoading,
  formErrorMessage,
}: IEventBinPath) {
  const [selectedDate, setSelectedDate] = useState(null);

  const onDateChange = (date: any) => {
    setSelectedDate(date);

    const [day, month, year] = [
      date.getDate(),
      date.getMonth(),
      date.getFullYear(),
    ];

    const formattedDate =
      (day < 10 ? "0" + day : day) +
      "/" +
      (month + 1 < 10 ? "0" + (month + 1) : month + 1) +
      "/" +
      year;

    updateServiceRequest({
      ...serviceRequest,
      eventBins: {
        ...serviceRequest.eventBins,
        eventDate: formattedDate,
      },
    });
  };

  return (
    <motion.form
      variants={animationVariants}
      initial="initial"
      animate="in"
      exit="out"
      className="flex flex-col items-center w-full"
      onSubmit={(e: FormEvent) => {
        e.preventDefault();
        // prevents double submission
        if (!isLoading) {
          let updatedComment = serviceRequest.comments
            ? serviceRequest.comments +
              `. No. of people expected: ${serviceRequest.eventBins.eventNumber?.toString()}`
            : (serviceRequest.comments = `No. of people expected: ${serviceRequest.eventBins.eventNumber?.toString()}`);
          updateServiceRequest({
            ...serviceRequest,
            comments: updatedComment,
          });
          onSubmit();
        }
      }}
    >
      <motion.div variants={animationVariants} className="w-full">
        <StyledInput
          label="Enter your full name"
          onChange={(e) =>
            updateServiceRequest({
              ...serviceRequest,
              name: (e.target as HTMLInputElement).value,
            })
          }
        />
      </motion.div>
      <motion.div variants={animationVariants} className="w-full">
        <StyledInput
          label="Enter your email address"
          onChange={(e) =>
            updateServiceRequest({
              ...serviceRequest,
              emailAddress: (e.target as HTMLInputElement).value,
            })
          }
        />
      </motion.div>
      <motion.div variants={animationVariants} className="w-full">
        <StyledInput
          label="Enter your phone number"
          type="number"
          onChange={(e) =>
            updateServiceRequest({
              ...serviceRequest,
              phoneNo: (e.target as HTMLInputElement).value,
            })
          }
        />
      </motion.div>
      <motion.div variants={animationVariants} className="w-full">
        <StyledInput
          label="Enter the name of your event"
          onChange={(e) =>
            updateServiceRequest({
              ...serviceRequest,
              eventBins: {
                ...serviceRequest.eventBins,
                eventName: (e.target as HTMLInputElement).value,
              },
            })
          }
        />
      </motion.div>
      <motion.div variants={animationVariants} className="w-full mb-4">
        <DatePicker
          selected={selectedDate}
          onChange={(date: any) => onDateChange(date)}
          className="w-full p-3 shadow-sm rounded flex relative"
          placeholderText="Click to select a date"
          dateFormat="dd/MM/yyyy"
        />
      </motion.div>
      <motion.div variants={animationVariants} className="w-full">
        <StyledInput
          label="How many people are you expecting at your event?"
          type="number"
          onChange={(e) =>
            updateServiceRequest({
              ...serviceRequest,
              eventBins: {
                ...serviceRequest.eventBins,
                eventNumber: (e.target as HTMLInputElement).value,
              },
            })
          }
        />
      </motion.div>
      <motion.div variants={animationVariants} className="w-full">
        <StyledTextArea
          label="Add any additional comments (optional)"
          required={false}
          onChange={(e) =>
            updateServiceRequest({
              ...serviceRequest,
              comments: (e.target as HTMLInputElement).value,
            })
          }
        />
      </motion.div>
      {formErrorMessage && (
        <motion.div
          variants={animationVariants}
          className="mb-4 bg-white rounded w-full"
        >
          <div className="m-2 p-2 relative border border-red-400 rounded flex items-center justify-end">
            <p className="mr-0 flex-grow text-red-400 ">{formErrorMessage}</p>
            <div className="flex-shrink-0 mr-2">
              <ExclamationMark />
            </div>
          </div>
        </motion.div>
      )}
      <motion.div
        variants={animationVariants}
        className="w-full flex justify-end"
      >
        <button
          type="submit"
          className="body-font disabled-button-style w-28 xl:w-40 text-white bg-wdc-green transition duration-300 ease-in-out hover:bg-wdc-green-lighter rounded py-2.5 md:py-3 focus:outline-none border border-transparent focus:border-gray-800"
        >
          {isLoading ? <DotSpinner /> : "Submit"}
        </button>
      </motion.div>
    </motion.form>
  );
};

export default EventBinPath;
