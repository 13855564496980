import { IStyledRadio } from "../../core/types/types";

function StyledRadio({ id, label, onChange, required = true }: IStyledRadio) {
  return (
    <div className="flex flex-col items-start w-full mb-4 bg-white rounded px-4 py-3 border border-gray-300 shadow-sm">
      <label htmlFor={id} className="text-base text-gray-500 pb-4">
        {label}
      </label>
      <div className="flex flex-col items-start">
        <div className="grid grid-cols-2 gap-10" onChange={onChange}>
          <label
            htmlFor={id + "true"}
            className="flex flex-row items-center select-none cursor-pointer"
          >
            <input
              id={id + "true"}
              name={id}
              type="radio"
              value="true"
              className="mr-1 cursor-pointer"
              required={required}
            />
            Yes
          </label>
          <label
            htmlFor={id + "false"}
            className="flex flex-row items-center select-none cursor-pointer"
          >
            <input
              id={id + "false"}
              name={id}
              type="radio"
              value="false"
              className="mr-1 cursor-pointer"
            />
            No
          </label>
        </div>
      </div>
    </div>
  );
};

export default StyledRadio;
