import DevLogin from "./components/DevLogin/DevLogin";
import Footer from "./components/Footer/Footer";
import Confirmation from "./pages/Confirmation";
import NotFound from "./pages/NotFound";
import Nav from "./components/Nav/Nav";
import Home from "./pages/Home";
import { Route, Routes, useLocation } from "react-router";
import { IServiceRequest } from "./core/types/types";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import "./styles/styles.css";
import { envConfig } from "./core/environment/envConfig";

function App() {
  // For dev login
  const [isLoggedIn, updateLogIn] = useState(false);

  // For router
  const location = useLocation();

  // Initial state for the main service request
  const initialState: IServiceRequest = {
    ticketNumber: 0,
    serviceRequest: null,
    service: [],
    binSize: [],
    comments: null,
    addressComponents: {
      FormattedAddress: null,
      StreetNumber: null,
      RoadName: null,
      SuburbName: null,
      CityName: null,
      PostCode: null,
      RegionName: null,
      Latitude: null,
      Longitude: null,
    },
    name: null,
    emailAddress: null,
    phoneNo: null,
    valuationNumber: null,
    ruralRecyclingDepot: null,
    wasteDetails: {
      wasteDescription: null,
      approxAmount: null,
      duration: null,
      whoDumped: null,
      occurredPreviously: null,
      hazardousItems: null,
      didWitness: null,
    },
    publicLitterType: null,
    eventBins: {
      eventName: null,
      eventDate: null,
      eventNumber: null,
    }
  };

  // State for the main service request
  const [serviceRequest, updateServiceRequest] = useState(initialState);

  // State for page navigation management
  const [hasCompletedForm, updateHasCompletedForm] = useState(false);

  return envConfig.requireLogin && !isLoggedIn ? (
    <DevLogin updateLogin={updateLogIn} />
  ) : (
    <div className="overflow-x-hidden">
      <div className="min-h-screen bg-wdc-background bg-wdc-primary bg-cover bg-center bg-fixed bg-no-repeat w-full flex flex-col items-center justify-start relative">
        <Nav />
        <div className="bg-wdc-grey h-10 w-full"></div>
        <AnimatePresence>
          <Routes location={location} key={location.key}>
            <Route path="/" element={<Home
                serviceRequest={serviceRequest}
                updateServiceRequest={updateServiceRequest}
                initialState={initialState}
                hasCompletedForm={hasCompletedForm}
                updateHasCompletedForm={updateHasCompletedForm}
            />} />
            <Route path="/confirmation"
              key="confirmation-page"
              element={<Confirmation hasCompletedForm={hasCompletedForm} serviceRequest={serviceRequest} />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </AnimatePresence>
        <div className="bg-wdc-primary h-10 w-full self-stretch absolute bottom-0">
          <div className="bg-wdc-primary transform -translate-x-1/2 rotate-45 h-10 w-10 absolute left-1/2 -bottom-1/2"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
