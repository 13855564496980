import { useState } from "react";
import { FixedServiceRequestTypes } from "../core/constants/constants";
import { apiService } from "../core/services/apiService";
import { AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { AxiosResponse } from "axios";
import {
  IAddressComponents,
  IHome,
  IServiceRequestWithServiceString,
} from "../core/types/types";
import StandardServicePath from "../paths/StandardServicePath/StandardServicePath";
import MandatoryCommentPath from "../paths/StandardServicePath/MandatoryCommentPath";
import SpilledWastePath from "../paths/StandardServicePath/SpilledWastePath";
import ServiceRequestWithMapPath from "../paths/StandardServicePath/ServiceRequestWithMapPath";
import EventBinPath from "../paths/StandardServicePath/EventBinPath";
import StyledSelect from "../components/StyledInputs/StyledSelect";

function Home({
  initialState,
  serviceRequest,
  updateServiceRequest,
  updateHasCompletedForm,
}: IHome) {
  const navigate = useNavigate();

  // state for custom validation/form error messages
  const [formErrorMessage, updateFormErrorMessage] = useState<string | null>(null); // prettier-ignore
  const [checkboxErrorMessage, updateCheckboxErrorMessage] = useState<string | null>(null); // prettier-ignore
  const [valuationNoError, updateValuationNoError] = useState<string | null>(null); // prettier-ignore
  const [wasteDescError, updateWasteDescError] = useState<string | null>(null); // prettier-ignore
  const errors = {
    checkboxError: "You need to select a service from the checkboxes above.",
    addressValidationError: "We were unable to find an address with those details. Please check your details or contact us.", // prettier-ignore
    specialCharValidationError:
      "Please remove all specials characters from this field",
    invalidCustomerNoError: "Please enter a valid rates valuation number",
    apiError:
      "Something went wrong with this request. Please try again later or contact us.",
  };

  // state for manual entry of addresses
  const [manualAddress, updateManualAddress] = useState(false);
  const [backupStreetNo, updateBackupStreetNo] = useState("");
  const [backupStreetName, updateBackupStreetName] = useState("");
  const [backupPostCode, updateBackupPostCode] = useState("");

  // state for waste details when 'other' is selected in multi-select
  const [otherWasteDetails, updateOtherWasteDetails] = useState("");

  // loading state
  const [isLoading, updateIsLoading] = useState(false);

  // SUBMIT METHOD FOR SERVICE REQUESTS 
  async function onSubmit() {
    updateIsLoading(true);
    updateFormErrorMessage(null);
    if (
      customValidation() &&
      (await validateResidentOrRatepayer())
    ) {
      const serviceRequestWithServiceString: IServiceRequestWithServiceString =
        Object.assign(
          {},
          serviceRequest,
          { service: serviceRequest.service?.toString() },
          { binSize: serviceRequest.binSize?.toString() },
          { ruralRecyclingDepot: serviceRequest.ruralRecyclingDepot?.toString() }
        );
      const response = await apiService.createServiceRequest(
        serviceRequestWithServiceString
      );

      if (serviceRequest.wasteDetails.wasteDescription?.includes("Other")) {
        serviceRequestWithServiceString.wasteDetails.wasteDescription =
        serviceRequest.wasteDetails.wasteDescription + `, ${otherWasteDetails}`;
      }

      handleResponse(response);
    }
    updateIsLoading(false);
}

  // VALIDATE EITHER AN ADDRESS OR AN ADDRESS AND VALUATIONS NUMBER WITH WDC DATA
  async function validateResidentOrRatepayer(): Promise<boolean> {
    if(serviceRequest.serviceRequest === FixedServiceRequestTypes.RURAL_RECYCLING_DEPOT ||
       serviceRequest.serviceRequest === FixedServiceRequestTypes.EVENT_BINS) {
      return true
    }

    const valuationNumber = serviceRequest.valuationNumber;
    const { StreetNumber, RoadName, PostCode } =
      serviceRequest.addressComponents;

    let houseNo = StreetNumber;
    let houseName = RoadName;
    let newPostCode = PostCode;

    // use backup address components if manual address mode is on
    if (manualAddress) {
      houseNo = backupStreetNo;
      houseName = backupStreetName;
      newPostCode = backupPostCode;
    }

    const response = await apiService.checkAddress(
      houseNo,
      houseName,
      newPostCode,
      valuationNumber
    );

    if (response?.status === 200) {
      // hydrate address components if address was entered manually
      if (manualAddress && isAddressComponents(response.data)) {
        serviceRequest.addressComponents = response.data;
      }
      return true;
    } else if (response?.status === 400 && response?.data === "Failed") {
      updateFormErrorMessage(errors.addressValidationError);
      return false;
    }
    updateFormErrorMessage(errors.apiError);
    return false;
  }

  // CUSTOM FORM VALIDATION FOR CUSTOMER NUMBER & ASSESSMENT NUMBER ON ADDITIONAL BIN PATH
  function customValidation(): boolean {
    let isValid = true;
    // validate service checkboxes when they are present
    if (
      (serviceRequest.serviceRequest === FixedServiceRequestTypes.DAMAGED_BIN ||
        serviceRequest.serviceRequest === FixedServiceRequestTypes.MISSING_BIN ||
        serviceRequest.serviceRequest === FixedServiceRequestTypes.ADDITIONAL_BIN ||
        serviceRequest.serviceRequest === FixedServiceRequestTypes.MISSED_COLLECTION||
        serviceRequest.serviceRequest === FixedServiceRequestTypes.REMOVE_BIN) &&
      (serviceRequest.service === null || !serviceRequest.service.length)
    ) {
      isValid = false;
      updateCheckboxErrorMessage(errors.checkboxError);
    }
    if (
      serviceRequest.serviceRequest === FixedServiceRequestTypes.ADDITIONAL_BIN || 
      serviceRequest.serviceRequest === FixedServiceRequestTypes.REMOVE_BIN
    ) {
      if (
        serviceRequest.valuationNumber != null &&
        RegExp(/[^A-Za-z0-9]/g).test(serviceRequest.valuationNumber)
      ) {
        isValid = false;
        updateValuationNoError(errors.specialCharValidationError);
      }
      if (
        serviceRequest.valuationNumber === null ||
        serviceRequest.valuationNumber.length === 0 ||
        parseInt(serviceRequest.valuationNumber) + 1 === 1
      ) {
        isValid = false;
        updateValuationNoError(errors.invalidCustomerNoError);
      }
    }
    return isValid;
  }

  // RESPONSE HANDLER FOR BOTH SUBMIT METHODS
  function handleResponse(response: AxiosResponse) {
    if (response?.status === 200) {
      if (parseInt(response.data)) {
        serviceRequest.ticketNumber = parseInt(response.data);
        updateHasCompletedForm(true);
        updateIsLoading(false);
        navigate("/confirmation");
      }
    }  else {
      updateIsLoading(false);
      updateFormErrorMessage(errors.apiError);
    }
  }

  // TYPE GUARD CHECK TO ENSURE CHECKADDRESS API RESPONSE IS OF TYPE IADDRESSCOMPONENTS
  function isAddressComponents(object: any): object is IAddressComponents {
    return (object as IAddressComponents).FormattedAddress !== undefined;
  }

  return (
    <div className="sm:mt-10 mb-10 w-11/12 md:w-8/12 xl:7/12">
      <div className="py-12 my-auto mx-auto flex flex-col items-start justify-start xl:w-4/5 2xl:w-7/12">
        <h1 className="heading-font w-full text-left mb-6">
          Report an issue with <br />
          your waste services
        </h1>
        {/******** SERVICE REQUEST DROPDOWN ********/}
        <StyledSelect
          label="Please select your service request"
          dropdownItems={FixedServiceRequestTypes.serviceRequestList}
          onChange={(e) => {
            // when path is change, reset service request
            // and all custom validation & loading states
            updateIsLoading(false);
            updateFormErrorMessage(null);
            updateCheckboxErrorMessage(null);
            updateValuationNoError(null);
            updateManualAddress(false);
            updateBackupStreetNo("");
            updateBackupStreetName("");
            updateBackupPostCode("");
            updateServiceRequest({
              ...initialState,
              serviceRequest: (e.target as HTMLInputElement).value,
            });
          }}
        />
        {/******** PATH FOR DAMAGED BIN SERVICE REQUESTS ********/}
        <AnimatePresence>
          {serviceRequest?.serviceRequest ===
            FixedServiceRequestTypes.DAMAGED_BIN && (
            <StandardServicePath
              key={1}
              serviceRequest={serviceRequest}
              updateServiceRequest={updateServiceRequest}
              onSubmit={onSubmit}
              checkboxErrorMessage={checkboxErrorMessage}
              updateCheckboxErrorMessage={updateCheckboxErrorMessage}
              isLoading={isLoading}
              formErrorMessage={formErrorMessage}
              valuationNoError={valuationNoError}
              updateValuationNoError={updateValuationNoError}
              manualAddress={manualAddress}
              updateManualAddress={updateManualAddress}
              updateBackupStreetNo={updateBackupStreetNo}
              updateBackupStreetName={updateBackupStreetName}
              updateBackupPostCode={updateBackupPostCode}
            />
          )}
          {/******** PATH FOR MISSING BIN SERVICE REQUESTS ********/}
          {serviceRequest?.serviceRequest ===
            FixedServiceRequestTypes.MISSING_BIN && (
            <StandardServicePath
              key={2}
              serviceRequest={serviceRequest}
              updateServiceRequest={updateServiceRequest}
              onSubmit={onSubmit}
              checkboxErrorMessage={checkboxErrorMessage}
              updateCheckboxErrorMessage={updateCheckboxErrorMessage}
              isLoading={isLoading}
              formErrorMessage={formErrorMessage}
              valuationNoError={valuationNoError}
              updateValuationNoError={updateValuationNoError}
              manualAddress={manualAddress}
              updateManualAddress={updateManualAddress}
              updateBackupStreetNo={updateBackupStreetNo}
              updateBackupStreetName={updateBackupStreetName}
              updateBackupPostCode={updateBackupPostCode}
            />
          )}
          {/******** PATH FOR ADDITIONAL BIN SERVICE REQUESTS ********/}
          {serviceRequest?.serviceRequest ===
            FixedServiceRequestTypes.ADDITIONAL_BIN && (
            <StandardServicePath
              key={3}
              serviceRequest={serviceRequest}
              updateServiceRequest={updateServiceRequest}
              onSubmit={onSubmit}
              checkboxErrorMessage={checkboxErrorMessage}
              updateCheckboxErrorMessage={updateCheckboxErrorMessage}
              isLoading={isLoading}
              formErrorMessage={formErrorMessage}
              valuationNoError={valuationNoError}
              updateValuationNoError={updateValuationNoError}
              manualAddress={manualAddress}
              updateManualAddress={updateManualAddress}
              updateBackupStreetNo={updateBackupStreetNo}
              updateBackupStreetName={updateBackupStreetName}
              updateBackupPostCode={updateBackupPostCode}
            />
          )}
          {/******** PATH FOR MISSED COLLECTION SERVICE REQUESTS ********/}
          {serviceRequest?.serviceRequest ===
            FixedServiceRequestTypes.MISSED_COLLECTION && (
            <StandardServicePath
              key={4}
              serviceRequest={serviceRequest}
              updateServiceRequest={updateServiceRequest}
              onSubmit={onSubmit}
              checkboxErrorMessage={checkboxErrorMessage}
              updateCheckboxErrorMessage={updateCheckboxErrorMessage}
              isLoading={isLoading}
              formErrorMessage={formErrorMessage}
              valuationNoError={valuationNoError}
              updateValuationNoError={updateValuationNoError}
              manualAddress={manualAddress}
              updateManualAddress={updateManualAddress}
              updateBackupStreetNo={updateBackupStreetNo}
              updateBackupStreetName={updateBackupStreetName}
              updateBackupPostCode={updateBackupPostCode}
            />
          )}
          {/******** PATH FOR RURAL RECYCLING DEPOT SERVICE REQUESTS ********/}
          {serviceRequest?.serviceRequest ===
            FixedServiceRequestTypes.RURAL_RECYCLING_DEPOT && (
            <MandatoryCommentPath 
              key={5}
              serviceRequest={serviceRequest}
              updateServiceRequest={updateServiceRequest}
              onSubmit={onSubmit}
              isLoading={isLoading}
              formErrorMessage={formErrorMessage}
              manualAddress={manualAddress}
              updateManualAddress={updateManualAddress}
              updateBackupStreetNo={updateBackupStreetNo}
              updateBackupStreetName={updateBackupStreetName}
              updateBackupPostCode={updateBackupPostCode}
            />
          )}
          {/******** PATH FOR ILLEGAL DUMPING SERVICE REQUESTS ********/}
          {serviceRequest?.serviceRequest === FixedServiceRequestTypes.SPILLED_WASTE && (
            <SpilledWastePath
              key={6}
              serviceRequest={serviceRequest}
              updateServiceRequest={updateServiceRequest}
              onSubmit={onSubmit}
              isLoading={isLoading}
              formErrorMessage={formErrorMessage}
              updateOtherWasteDetails={updateOtherWasteDetails}
              wasteDescError={wasteDescError}
              updateWasteDescError={updateWasteDescError}
            />
          )}
          {/******** PATH FOR REMOVE BIN SERVICE REQUESTS ********/}
          {serviceRequest?.serviceRequest ===
            FixedServiceRequestTypes.REMOVE_BIN && (
            <StandardServicePath
              key={7}
              serviceRequest={serviceRequest}
              updateServiceRequest={updateServiceRequest}
              onSubmit={onSubmit}
              checkboxErrorMessage={checkboxErrorMessage}
              updateCheckboxErrorMessage={updateCheckboxErrorMessage}
              isLoading={isLoading}
              formErrorMessage={formErrorMessage}
              valuationNoError={valuationNoError}
              updateValuationNoError={updateValuationNoError}
              manualAddress={manualAddress}
              updateManualAddress={updateManualAddress}
              updateBackupStreetNo={updateBackupStreetNo}
              updateBackupStreetName={updateBackupStreetName}
              updateBackupPostCode={updateBackupPostCode}
            />
          )}
          {/******** PATH FOR CUSTOMER COMPLAINT SERVICE REQUESTS ********/}
          {serviceRequest?.serviceRequest ===
            FixedServiceRequestTypes.CUSTOMER_COMPLAINT && (
            <MandatoryCommentPath 
              key={8}
              serviceRequest={serviceRequest}
              updateServiceRequest={updateServiceRequest}
              onSubmit={onSubmit}
              isLoading={isLoading}
              formErrorMessage={formErrorMessage}
              manualAddress={manualAddress}
              updateManualAddress={updateManualAddress}
              updateBackupStreetNo={updateBackupStreetNo}
              updateBackupStreetName={updateBackupStreetName}
              updateBackupPostCode={updateBackupPostCode}
            />
          )}
          {/******** PATH FOR PUBLIC PLACE BIN SERVICE REQUESTS ********/}
          {serviceRequest?.serviceRequest === FixedServiceRequestTypes.PUBLIC_PLACE_BINS && (
            <ServiceRequestWithMapPath
              key={9}
              serviceRequest={serviceRequest}
              updateServiceRequest={updateServiceRequest}
              onSubmit={onSubmit}
              isLoading={isLoading}
              formErrorMessage={formErrorMessage}
            />
          )}
          {/******** PATH FOR RESOURCE RECOVERY PARK SERVICE REQUESTS ********/}
          {serviceRequest?.serviceRequest ===
            FixedServiceRequestTypes.RESOURCE_RECOVERY_PARK && (
            <MandatoryCommentPath 
              key={10}
              serviceRequest={serviceRequest}
              updateServiceRequest={updateServiceRequest}
              onSubmit={onSubmit}
              isLoading={isLoading}
              formErrorMessage={formErrorMessage}
              manualAddress={manualAddress}
              updateManualAddress={updateManualAddress}
              updateBackupStreetNo={updateBackupStreetNo}
              updateBackupStreetName={updateBackupStreetName}
              updateBackupPostCode={updateBackupPostCode}
            />
          )}
          {/******** PATH FOR EVENT BIN SERVICE REQUESTS ********/}
          {serviceRequest?.serviceRequest ===
            FixedServiceRequestTypes.EVENT_BINS && (
            <EventBinPath
              key={11}
              serviceRequest={serviceRequest}
              updateServiceRequest={updateServiceRequest}
              onSubmit={onSubmit}
              isLoading={isLoading}
              formErrorMessage={formErrorMessage}
            />
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Home;
