import { IStyledCheckboxes } from "../../core/types/types";
import { FixedServiceTypes } from "../../core/constants/constants";
import ExclamationMark from "../ExclamationMark/ExclamationMark";

function StyledCheckboxes({
  serviceRequest,
  label,
  checkboxValuesList,
  onChange,
  checkboxErrorMessage,
  disableRubbish,
  disableGlass,
}: IStyledCheckboxes) {
  return (
    <div className="flex flex-col items-start w-full mb-4 bg-white rounded px-4 py-3 border">
      <label htmlFor={label} className="text-base text-gray-500 pb-4">
        {label}
      </label>
      <div className="grid grid-cols-2 gap-1 w-full">
        {Object.entries(checkboxValuesList).map(
          ([itemKey, itemValue]: [string, string], index: number) => {
            return (
              <div className="flex flex-row items-center w-full" key={index}>
                <input
                  id={itemKey}
                  type="checkbox"
                  name={itemKey}
                  value={itemKey}
                  title={label}
                  checked={
                    serviceRequest.service?.includes(itemKey)
                      ? true
                      : false || serviceRequest.binSize?.includes(itemKey)
                      ? true
                      : false
                  }
                  className={`mr-2 ${
                    (disableRubbish &&
                      itemKey.includes(FixedServiceTypes.RUBBISH) &&
                      !serviceRequest.binSize.includes(itemKey)) ||
                    (disableGlass &&
                      itemKey.includes(FixedServiceTypes.GLASS) &&
                      !serviceRequest.binSize.includes(itemKey))
                      ? ""
                      : "cursor-pointer"
                  }`}
                  onChange={onChange}
                  disabled={(disableRubbish &&
                    itemKey.includes(FixedServiceTypes.RUBBISH) &&
                    !serviceRequest.binSize.includes(itemKey)) ||
                  (disableGlass &&
                    itemKey.includes(FixedServiceTypes.GLASS) &&
                    !serviceRequest.binSize.includes(itemKey)) ? true : false}
                />
                <label
                  htmlFor={itemKey}
                  className={`text-base ${
                    (disableRubbish &&
                      itemKey.includes(FixedServiceTypes.RUBBISH) &&
                      !serviceRequest.binSize.includes(itemKey)) ||
                    (disableGlass &&
                      itemKey.includes(FixedServiceTypes.GLASS) &&
                      !serviceRequest.binSize.includes(itemKey))
                      ? "text-gray-300"
                      : "text-gray-800 cursor-pointer"
                  }`}
                >
                  {itemValue}
                </label>
              </div>
            );
          }
        )}
      </div>
      {checkboxErrorMessage && (
        <div className="mt-4 p-2 w-full border border-red-400 rounded flex items-center">
          <p className="text-base text-red-400 flex-grow mr-4">
            {checkboxErrorMessage}
          </p>
          <div className="mr-2 flex-shrink-0">
            <ExclamationMark />
          </div>
        </div>
      )}
    </div>
  );
};

export default StyledCheckboxes;
