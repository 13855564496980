import React, { useState } from "react";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import CircleSpinner from "../CircleSpinner/CircleSpinner";
import { IAddressAutocomplete } from "../../core/types/types";
import ExclamationMark from "../ExclamationMark/ExclamationMark";

function AddressAutocomplete({
  label,
  serviceRequest,
  updateServiceRequest,
  addressErrorMessage,
  updateAddressErrorMessage,
  isValidAddress,
  updateIsValidAddress,
  updateBackupStreetNo,
  updateBackupStreetName,
  updateBackupPostcode,
  updateManualAddress,
}: IAddressAutocomplete) {
  const [isLoading, updateLoading] = useState(false);
  const errors = {
    addressNotFound:
      "We were unable to find your address. Please try again or contact us on 07 577 7000.",
    apiError: "We were unable to make this request. Please try again or contact us on 07 577 7000.",
    noAddressSelected: "You need to select an address from the dropdown menu.",
  };

  // handles user typing into the address autocomplete field
  function handleChange(value: string) {
    updateAddressErrorMessage(null);
    updateIsValidAddress(false);

    // reset all backup address components if a user switches back to autocomplete
    if (updateBackupStreetNo !== undefined) {
      updateBackupStreetNo("");
    }
    if (updateBackupStreetName !== undefined) {
      updateBackupStreetName("");
    }
    if (updateBackupPostcode !== undefined) {
      updateBackupPostcode("");
    }

    updateServiceRequest({
      ...serviceRequest,
      addressComponents: {
        StreetNumber: null,
        RoadName: null,
        SuburbName: null,
        CityName: null,
        RegionName: null,
        PostCode: null,
        Latitude: null,
        Longitude: null,
        FormattedAddress: value,
      },
    });
  }

  // Handles the selection of an address, checks for valid post codes
  async function handleSelect(value: string) {
    if (value === "") return;
    updateLoading(true);
    updateAddressErrorMessage(null);

    try {
      const [fullAddress] = await geocodeByAddress(value);
      const subpremise = extractAddressComponents(fullAddress.address_components, "subpremise");
      const roadName = extractAddressComponents(fullAddress.address_components, "route");
      const suburbName = extractAddressComponents(fullAddress.address_components, "sublocality");
      const cityName = extractAddressComponents(fullAddress.address_components, "locality");
      const postCode = extractAddressComponents(fullAddress.address_components, "postal_code");
      const regionName = extractAddressComponents(fullAddress.address_components, "administrative_area_level_1"); // prettier-ignore
      const streetNumber = extractAddressComponents(fullAddress.address_components, "street_number"); // prettier-ignore
      const latLng = await getLatLng(fullAddress);
      const { lat: latitude, lng: longitude } = latLng;

      var formattedSubpremise = subpremise === "" ? "" : subpremise + "/";

      updateAddressErrorMessage(null);
      updateIsValidAddress(true);
      updateServiceRequest({
        ...serviceRequest,
        addressComponents: {
          ...serviceRequest.addressComponents,
          StreetNumber: formattedSubpremise + streetNumber,
          RoadName: roadName,
          SuburbName: suburbName,
          CityName: cityName,
          RegionName: regionName,
          PostCode: postCode,
          Latitude: latitude,
          Longitude: longitude,
          FormattedAddress: fullAddress.formatted_address,
        },
      });

      // update loading state before possibly switching to manual address mode
      updateLoading(false);

      // switch to manual address mode if street number is not found
      if (updateManualAddress !== undefined && streetNumber === "") {
        updateManualAddress(true);
      }
    } catch (error) {
      updateLoading(false);
      updateAddressErrorMessage(errors.apiError);
    }
  }

  // Pull parts of an address out of the Google Maps geocoder result for API consumption
  function extractAddressComponents(
    components: google.maps.GeocoderAddressComponent[],
    target: string
  ) {
    const { long_name: addressComponent = "" } =
      components.find((c) => c.types.includes(target)) || {};
    return addressComponent === null ? "" : addressComponent;
  }

  // Clicking on an address does not set the address field value or fire handleSelect they must be done manually.
  function handleClick(value: string) {
    updateServiceRequest({
      ...serviceRequest,
      addressComponents: {
        ...serviceRequest.addressComponents,
        FormattedAddress: value,
      },
    });
    handleSelect(value);
  }

  // Handles for when a user clicks or tabs out of the dropdown menu without selecting an address
  function handleBlur(e: React.SyntheticEvent) {
    if (
      isLoading ||
      serviceRequest.addressComponents.FormattedAddress === null ||
      serviceRequest.addressComponents.FormattedAddress === "" ||
      isValidAddress ||
      addressErrorMessage !== null
    ) {
      return;
    }
    updateAddressErrorMessage(errors.noAddressSelected);
  }

  // Restricts autofilled addresses to NZ only
  const searchOptions = {
    types: ["address"],
    componentRestrictions: { country: ["nz"] },
  };

  // Classes for address box, suggestion boxes and final suggestion box
  const boxStyle =
    "w-full pl-3 py-3 focus:outline-none text-base placeholder-gray-500 text-gray-800";
  const addressBox = `${boxStyle} transition duration-300 ease-in-out pr-3 overflow-ellipsis whitespace-nowrap overflow-x-hidden focus:outline-none rounded border border-gray-300 shadow-sm focus:border-gray-800`;
  const suggestionBoxes = `${boxStyle} border-t border-l border-r border-gray-300 bg-white hover:bg-gray-100 flex flex-row items-center min-w-0 rounded-none`;
  const firstBox = `${suggestionBoxes}`;

  return (
    <div className="w-full h-full z-50">
      <PlacesAutocomplete
        value={
          serviceRequest.addressComponents?.FormattedAddress === null
            ? ""
            : serviceRequest.addressComponents.FormattedAddress
        }
        onSelect={handleSelect}
        searchOptions={searchOptions}
        onChange={(value) => handleChange(value)}
      >
        {({ getInputProps, suggestions, loading }) => (
          <div
            className="relative w-full h-full bg-white rounded inline-block"
            onBlur={(e) => handleBlur(e)}
          >
            <input
              data-lpignore="true"
              name="StreetAddress"
              placeholder={label}
              title={label}
              id="StreetAddress"
              {...getInputProps({
                className: `${addressBox}`,
                autoComplete: "none",
                required: true,
                results: 3,
              })}
            />
            {addressErrorMessage && (
              <div className="mx-4 mt-4 mb-2 p-2 border border-red-400 rounded flex items-center">
                <p className="text-red-400 flex-grow mr-4">{addressErrorMessage}</p>
                <div className="flex-shrink-0">
                  <ExclamationMark />
                </div>
              </div>
            )}
            {loading || isLoading ? (
              <div className="absolute right-2 top-3 background-white-ie-fix w-8 h-6">
                <CircleSpinner />
              </div>
            ) : (
              <div></div>
            )}
            <div className="absolute w-full shadow-md z-50">
              {suggestions.map((suggestion, i) => {
                // restricts number of autocomplete suggestions for UI reasons
                if (i < 3) {
                  const style = suggestion.active ? `bg-gray-100 cursor-pointer` : "cursor-pointer";
                  return (
                    <div
                      key={i}
                      className={i === 0 ? `${firstBox} ${style}` : `${suggestionBoxes} ${style}`}
                      onMouseDown={() => handleClick(suggestion.description)}
                    >
                      <p className="w-full truncate">{suggestion.description}</p>
                    </div>
                  );
                }
                return <div key={i}></div>;
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
};

export default AddressAutocomplete;
