export class FixedServiceRequestTypes {
  static DAMAGED_BIN = "DamagedBin";
  static MISSING_BIN = "MissingBin";
  static ADDITIONAL_BIN = "AdditionalBin";
  static MISSED_COLLECTION = "MissedCollection";
  static RURAL_RECYCLING_DEPOT = "RuralRecyclingDepot"
  static SPILLED_WASTE = "SpilledWaste"
  static REMOVE_BIN = "RemoveBin"
  static CUSTOMER_COMPLAINT = "CustomerComplaint"
  static PUBLIC_PLACE_BINS = "PublicPlaceBins";
  static RESOURCE_RECOVERY_PARK = "ResourceRecoveryPark"
  static EVENT_BINS = "EventBins"

  static serviceRequestList = {
    [FixedServiceRequestTypes.DAMAGED_BIN]: "Damaged Bin",
    [FixedServiceRequestTypes.MISSING_BIN]: "Missing Bin",
    [FixedServiceRequestTypes.ADDITIONAL_BIN]: "Additional Bins",
    [FixedServiceRequestTypes.MISSED_COLLECTION]: "Missed Collection",
    [FixedServiceRequestTypes.RURAL_RECYCLING_DEPOT]: "Rural Recycling Depot",
    [FixedServiceRequestTypes.SPILLED_WASTE]: "Spilled Waste",
    [FixedServiceRequestTypes.REMOVE_BIN]: "Remove Bins",
    [FixedServiceRequestTypes.CUSTOMER_COMPLAINT]: "Customer Complaint",
    [FixedServiceRequestTypes.PUBLIC_PLACE_BINS]: "Public Place bins",
    [FixedServiceRequestTypes.RESOURCE_RECOVERY_PARK]: "Resource Recovery Park",
    [FixedServiceRequestTypes.EVENT_BINS]: "Event Bins",
  };
}

export class FixedServiceTypes {
  static RUBBISH = "Rubbish";
  static RECYCLING = "Recycling";
  static ORGANICS = "Organics";
  static GLASS = "Glass";

  static serviceList = {
    [FixedServiceTypes.RUBBISH]: "Rubbish",
    [FixedServiceTypes.RECYCLING]: "Recycling",
    [FixedServiceTypes.ORGANICS]: "Organics",
    [FixedServiceTypes.GLASS]: "Glass",
  };
}

export class BinSizes {
  static RUBBISH_RED_140_LITRE = "Rubbish140Litre";
  static RUBBISH_RED_240_LITRE = "Rubbish240Litre"
  static RECYCLING_YELLOW_240_LITRE = "Recycling240Litre";
  static ORGANICS_GREEN_240_LITRE = "Organics240Litre";
  static GLASS_BLUE_45_LITRE = "Glass45Litre";
  static GLASS_BLUE_240_LITRE = "Glass240Litre";

  static binSizeList = {
    [BinSizes.RUBBISH_RED_140_LITRE]: "140 litre Rubbish (Red)",
    [BinSizes.RUBBISH_RED_240_LITRE]: "240 litre Rubbish (Red)",
    [BinSizes.RECYCLING_YELLOW_240_LITRE]: "240 litre Recycling (Yellow)",
    [BinSizes.ORGANICS_GREEN_240_LITRE]: "240 litre Organics (Green)",
    [BinSizes.GLASS_BLUE_45_LITRE]: "45 litre Glass (Blue)",
    [BinSizes.GLASS_BLUE_240_LITRE]: "240 litre Glass (Blue)",
  };
}

export class FixedPublicPlaceTypes {
  static OVERFLOWING_PUBLIC_BINS = "OverflowingPublicBins";
  static ODOROUS_PUBLIC_BINS = "OdorousPublicBins";
  static DAMAGED_PUBLIC_BINS = "DamagedOrVandalisedPublicBins";

  static publicPlaceBinsList = {
    [FixedPublicPlaceTypes.OVERFLOWING_PUBLIC_BINS]: "Overflowing public bins",
    [FixedPublicPlaceTypes.ODOROUS_PUBLIC_BINS]: "Odorous public bins",
    [FixedPublicPlaceTypes.DAMAGED_PUBLIC_BINS]:
      "Damaged or vandalised public bins",
  };
}

export const fixedIllegalDumpingDetails = [
  { value: "Furniture", label: "Furniture" },
  { value: "Mattresses", label: "Mattresses" },
  { value: "Household items", label: "Household items" },
  { value: "Rubbish bags", label: "Rubbish bags" },
  { value: "Scrap metal", label: "Scrap metal" },
  { value: "Garden waste", label: "Garden waste" },
  { value: "TV’s", label: "TV’s" },
  { value: "Tyres", label: "Tyres" },
  { value: "Homeless belongings", label: "Homeless belongings" },
  { value: "Pallets", label: "Pallets" },
  { value: "Clothing", label: "Clothing" },
  { value: "Other", label: "Other" },
];

export const fixedRuralRecyclingDepots = [
  { value: "Glenavy", label: "Glenavy" },
  { value: "Hakataramea", label: "Hakataramea" },
  { value: "Hunter", label: "Hunter" },
  { value: "Holme Station Corner", label: "Holme Station Corner" },
  { value: "Makikihi", label: "Makikihi" },
  { value: "Morven", label: "Morven" },
  { value: "St Andrews", label: "St Andrews" },
  { value: "Waihao Downs", label: "Waihao Downs" },
  { value: "Willowbridge", label: "Willowbridge" },
];

// Handles all entry and exit animations for the service request paths
export const animationVariants = {
  initial: {
    opacity: 0,
    scale: 0.9,
  },
  in: {
    opacity: 1,
    scale: 1,
    transition: {
      ease: "easeInOut",
      duration: 0.2,
      staggerChildren: 0.075,
    },
  },
  out: {
    opacity: 0,
    transition: { duration: 0.075 },
  },
};
